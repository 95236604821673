<template>
  <b-card>
    <div class="d-flex justify-content-start">
      <button
        class="compact ui button text-height"
        @click="onRefreshPage"
      >
        <refresh-cw-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Tải lại
      </button>
    </div>
    <good-table
      ref="kySo"
      class="mt-1"
      :columns="columns"
      :rows="danhSachKySo"
      :selectable="false"
      :total="tongSoKySo"
      :custom-status="false"
      @column-filter="layDanhSachKySo"
      @page-change="layDanhSachKySo"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'loaiKySo'"
          v-model="filterCondition.loaiKySo"
          v-format-v-select
          multiple
          class="vtreeselect-chooser"
          :options="loaiKySo"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn loại ký số"
          :clear-on-select="true"
          @input="layDanhSachKySo()"
        />
        <treeselect
          v-else-if="props.props.column.field === 'status'"
          v-model="filterCondition.status"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="[
            {label: 'Còn hiệu lực', id: true},
            {label: 'Hết hiệu lực', id: false},
          ]"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn trạng thái"
          :clear-on-select="true"
          @input="layDanhSachKySo()"
        />
      </template>
      <template
        slot="custom-row"
        slot-scope="props"
      >
        <div v-if="props.props.column.field === 'loaiKySo'">
          {{ loaiKySo.find(kyso => kyso.value === props.props.row.loaiKySo).label }}
        </div>
        <div v-else-if="props.props.column.field === 'status'">
          {{ props.props.row.status ? 'Còn hiệu lực' : 'Hết hiệu lực' }}
        </div>
      </template>
      <template
        slot="actions"
        slot-scope="props"
      >
        <div class="text-center">
          <feather-icon
            v-if="props.props.row.daDuyet"
            v-b-tooltip.hover.v-secondary
            title="Huỷ tiếp nhận"
            icon="XCircleIcon"
            size="18"
            @click="xuLyKySo(props.props.row.id, false)"
          />
          <feather-icon
            v-else
            v-b-tooltip.hover.v-secondary
            title="Tiếp nhận"
            icon="CheckCircleIcon"
            size="18"
            @click="xuLyKySo(props.props.row.id, true)"
          />
        </div>
      </template>
    </good-table>
  </b-card>
</template>

<script>
import GoodTable from '@/components/GoodTable.vue'
import { COLUMN_KY_SO } from '@/constants/constants'
import END_POINTS from '@/api/endpoints'
import { VBTooltip, BCard } from 'bootstrap-vue'
import {
  RefreshCwIcon,
} from 'vue-feather-icons'

export default {
  name: 'DanhSachKySo',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    GoodTable,
    BCard,
    RefreshCwIcon,
  },
  data() {
    return {
      columns: COLUMN_KY_SO,
      danhSachKySo: [],
      tongSoKySo: 0,
      filterCondition: {
        loaiKySo: [],
        serialNumber: '',
        nhaPhatHanh: '',
        thongTinNguoiKy: '',
        username: '',
        status: null,
        type: 'ADMIN',
        pageNumber: 1,
        pageSize: 10,
      },
      loaiKySo: [
        { label: 'Cá nhân', value: 1, id: 1 },
        { label: 'Doanh nghiệp, tổ chức', value: 2, id: 2 },
        { label: 'Cá nhân và doanh nghiệp tổ chức', value: 3, id: 3 },
      ],
    }
  },
  created() {
    this.layDanhSachKySo()
  },
  methods: {
    resetFilter() {
      this.filterCondition = {
        loaiKySo: [],
        serialNumber: '',
        nhaPhatHanh: '',
        thongTinNguoiKy: '',
        username: '',
        status: null,
        type: 'ADMIN',
        pageNumber: 1,
        pageSize: 10,
      }
    },
    onRefreshPage() {
      this.$refs.kySo.$refs.vbTables.reset()
      if (this.filterCondition.username || this.filterCondition.loaiKySo?.length === 0) {
        this.resetFilter()
        this.layDanhSachKySo()
      } else {
        this.resetFilter()
      }
    },
    layDanhSachKySo(param = {}) {
      let payload = {
        ...this.filterCondition,
        loaiKySo: this.filterCondition.loaiKySo.toString(),
        ...param,
      }
      if (this.$refs.kySo) {
        payload = {
          ...payload,
          ...this.$refs.kySo.getCurrentPage(),
        }
      }
      this.$axios.post(END_POINTS.KY_SO.LAY_DANH_SACH, this.$trimObject({
        ...payload,
      }), false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachKySo = response.data.data
          this.tongSoKySo = response.data.totalCount || 0
          this.$refs.kySo.resetCurrentPage(payload.pageNumber)
        }
      })
    },
    xuLyKySo(id, trangThai) {
      this.$axios.get(END_POINTS.KY_SO.CAP_NHAT_TRANG_THAI, {
        id,
        trangThai,
      }, true).then(response => {
        if (response.data && response.data.succeeded) {
          this.layDanhSachKySo()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
